import "pages/header/Index.css";

function Header() {
  return (
    <header className="header-wrapper">
      <h1 className="p-4">터널 관제 시스템</h1>
    </header>
  );
}

export default Header;
